// Copyright 2018 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Factory for creating and mutating instances of frontend
 * skill summary domain objects.
 */
var oppia = require('AppInit.ts').module;
oppia.factory('SkillSummaryObjectFactory', [
    function () {
        var SkillSummary = function (skillId, skillDescription) {
            this._id = skillId;
            this._description = skillDescription;
        };
        // Instance methods
        SkillSummary.prototype.getId = function () {
            return this._id;
        };
        SkillSummary.prototype.getDescription = function () {
            return this._description;
        };
        // TODO (ankita240796) Remove the bracket notation once Angular2 gets in.
        /* eslint-disable dot-notation */
        SkillSummary['create'] = function (skillId, skillDescription) {
            /* eslint-enable dot-notation */
            return new SkillSummary(skillId, skillDescription);
        };
        return SkillSummary;
    }
]);
