// Copyright 2018 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Controller for the main tab of the skill editor.
 */
require('pages/skill-editor-page/editor-tab/skill-description-editor/' +
    'skill-description-editor.directive.ts');
require('pages/skill-editor-page/editor-tab/skill-concept-card-editor/' +
    'skill-concept-card-editor.directive.ts');
require('pages/skill-editor-page/editor-tab/skill-misconceptions-editor/' +
    'skill-misconceptions-editor.directive.ts');
require('domain/utilities/UrlInterpolationService.ts');
require('pages/skill-editor-page/services/skill-editor-state.service.ts');
var oppia = require('AppInit.ts').module;
oppia.directive('skillEditorMainTab', [
    'SkillEditorStateService', 'UrlInterpolationService',
    function (SkillEditorStateService, UrlInterpolationService) {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: UrlInterpolationService.getDirectiveTemplateUrl('/pages/skill-editor-page/editor-tab/' +
                'skill-editor-main-tab.directive.html'),
            controller: [
                '$scope', 'SkillEditorStateService',
                function ($scope, SkillEditorStateService) {
                    $scope.hasLoadedSkill = SkillEditorStateService.hasLoadedSkill;
                }
            ]
        };
    }
]);
